export interface QueryPageProps {
    query?: { name: string; default?: string | number | string[] | number[] }[];
}

export interface QueryPageState {
    query: {
        [query: string]: null | string | number | string[] | number[] | undefined
    };
}

export const setDefaultQuerys = (args: QueryPageProps): QueryPageState => {
    const query = new URLSearchParams(window.location.search);
    let updated = false;
    const ret: QueryPageState = { query: {} };
    if (args.query) {
        for (const q of args.query) {
            ret.query[q.name] = query.has(q.name) ?
                (
                    typeof q.default === "number" ? parseInt(query.get(q.name) as string, 10) :
                        typeof q.default === "string" || q.default === undefined ? query.get(q.name) : query.getAll(q.name)
                ) : q.default;
            if (!query.has(q.name) && q.default !== undefined) {
                query.set(q.name, String(q.default));
                updated = true;
            }
        }
    }
    if (updated) {
        const obj = {
            Title: document.title,
            Url: `${window.location.href.split("?")[0]}?${query.toString()}`
        };
        window.history.pushState(obj, obj.Title, obj.Url);
    }
    return ret;
}

export const updateQuery = (queryName: string, value?: any) => {
    const query = new URLSearchParams(window.location.search);
    let updated = false;
    if (query.has(queryName) && value === undefined) {
        updated = true;
        query.delete(queryName);
    } else if (query.has(queryName) && query.get(queryName) !== String(value)) {
        updated = true;
        query.set(queryName, String(value));
    } else if (!query.has(queryName) && value !== undefined) {
        updated = true;
        query.set(queryName, String(value));
    }

    if (updated) {
        const obj = {
            Title: document.title,
            Url: `${window.location.href.split("?")[0]}?${query.toString()}`
        };
        window.history.pushState(obj, obj.Title, obj.Url);
    }
}