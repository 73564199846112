import React, { Component } from "react";
import { LoadingComponent } from "../Loading"
import { Parameter, getParameters, patchParameter, deleteParameter, postParameter } from "../../endpoints/parameter";
import Swal from "sweetalert2";
import { v4 } from "uuid";

export interface Row {

    parameter: Parameter;
    actions: {
        [key: string]: boolean
    }
}

export interface ParametersProps {
    group: string;
    actions: Array<"CREATE" | "UPDATE" | "DELETE">;
    attributes: Array<"code" | "value" | "active" | "group" | "createdAt" | "updatedAt">;
    translate: {
        code?: string,
        value?: string,
        active?: string,
        group?: string,
        createdAt?: string,
        updatedAt?: string
    }
    title: string;
    refresh: boolean;

}

export interface ParametersState {

    loading: boolean;
    rows: Array<Row>;
    error?: string;
}

export class ParametersComponent extends Component<ParametersProps, ParametersState>{

    constructor(props: ParametersProps) {
        super(props);

        this.state = {
            loading: false,
            rows: [],
        }

        this.getParameters = this.getParameters.bind(this);
        this.updateParameter = this.updateParameter.bind(this);
    }

    getParameters(group: string): void {
        this.setState({ loading: true }, async () => {
            const parameters = await getParameters(group);

            const rows: Array<Row> = [];

            const actions: {
                [key: string]: boolean
            } = {};

            this.props.actions.forEach(action => {
                actions[action] = true
            })

            parameters.forEach(parameter => {
                rows.push({
                    parameter,
                    actions
                })
            });

            this.setState({ rows, loading: false });
        })
    }

    async updateParameter(parameter: Parameter): Promise<void> {
        try {
            await patchParameter(parameter);

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Configuración guardada con exito ${this.props.refresh?", reiniciando...":""}`,
                showConfirmButton: false,
                timer: 1500,
                didClose: () => {
                    if (this.props.refresh)
                        window.location.reload();
                    else
                        this.getParameters(parameter.group);
                }
            })
        }
        catch (e: any) {
            this.setState({ error: e.message });
        }
    }

    async deleteParameter(parameter: Parameter): Promise<void> {
        try {
            await deleteParameter(parameter);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Parametro eliminado correctamente',
                showConfirmButton: false,
                timer: 1500,
                didClose: () => {
                    if (this.props.refresh)
                        window.location.reload();
                    else
                        this.getParameters(parameter.group);
                }
            })
        }
        catch (e: any) {
            this.setState({ error: e.message })
        }
    }

    async createParameter(parameter: Parameter): Promise<void> {
        try {
            await postParameter(parameter);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Parametro creado correctamente',
                showConfirmButton: false,
                timer: 1500,
                didClose: () => {
                    if (this.props.refresh)
                        window.location.reload();
                    else
                        this.getParameters(parameter.group);
                }
            })
        }
        catch (e: any) {
            this.setState({ error: e.message })
        }
    }

    showCUD(parameter: Parameter, action: "CREATE" | "UPDATE" | "DELETE"): void {

        let title = undefined;

        switch (action) {
            case "CREATE":
                title = "Crear parametro";
                break;
            case "UPDATE":
                title = "Actualizar parametro";
                break;
            case "DELETE":
                title = "Eliminar parametro"
                break;
        }

        let html = "";

        this.props.attributes.forEach(attr => {
            html += `<b>${this.props.translate[attr] ? this.props.translate[attr] : "no definido"}</b>
                    <input type="text" id="${attr}" class="swal2-input" placeholder="${this.props.translate[attr] ? this.props.translate[attr] : "no definido"}" value="${parameter[attr]}"/> <br/>`
        });

        Swal.fire({
            title: `${title}`,
            html,
            confirmButtonText: `${title.split(" ")[0]}`,
            confirmButtonColor: "#304c7d",
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then(async (result) => {
            if (result.isConfirmed) {
                switch (action) {
                    case "CREATE":
                        this.props.attributes.forEach(attr => {
                            if (attr === "code" || attr === "group" || attr === "value")
                                parameter[attr] = (document.querySelector(`#${attr}`) as any).value.toUpperCase();
                        });
                        await this.createParameter(parameter);
                        break;
                    case "UPDATE":
                        this.props.attributes.forEach(attr => {
                            if (attr === "code" || attr === "group" || attr === "value")
                                parameter[attr] = (document.querySelector(`#${attr}`) as any).value.toUpperCase();
                        });

                        await this.updateParameter(parameter);
                        break;
                    case "DELETE":
                        await this.deleteParameter(parameter);
                        break;
                }
            }
        })
    }


    componentDidMount(): void {
        this.getParameters(this.props.group);
    }

    renderHeader(): JSX.Element {
        return (
            <thead className="text-white" key={v4()} style={{ backgroundColor: "#304c7d" }}>
                <tr>
                    {
                        this.props.attributes.map(attr => {
                            return (
                                <th scope="col" key={v4()} className="text-center">{this.props.translate[attr] ? this.props.translate[attr] : attr}</th>
                            );
                        })
                    }
                    <th scope="col" key={v4()} className="text-center">Acciones</th>
                </tr>
            </thead>
        );
    }

    renderRow(row: Row): JSX.Element[] {

        const r = this.props.attributes.map(attr => {
            return (
                <td key={v4()} className='text-center'>
                    {row.parameter[attr]}
                </td>
            )
        })

        const action = <td className='text-center' key={v4()}>
            {
                this.props.actions.map(action => {

                    if (action === "UPDATE") {
                        return <i className="fas fa-edit ms-2 fa-lg text-warning" key={v4()} style={{ cursor: "pointer" }} onClick={async () => {
                            this.showCUD(row.parameter, action);
                            return <></>;
                        }}></i>;
                    }
                    else if (action === "DELETE") {
                        return <i className="fas fa-trash-alt ms-3 fa-lg text-danger" key={v4()} style={{ cursor: "pointer" }} onClick={async () => {
                            this.showCUD(row.parameter, action);
                            return <></>;
                        }}></i>;
                    }
                    else
                        return <></>
                })

            }
        </td>

        r.push(action);

        return r;

    }

    render(): JSX.Element {
        return (
            <div className="container mt-2" key={v4()}>
                <div className="card shadow" key={v4()}>
                    {this.state.error &&
                        <p>{this.state.error}</p>
                    }
                    {this.state.loading &&
                        <LoadingComponent />
                    }

                    {!this.state.loading && !this.state.error &&
                        <>
                            <div className="d-flex bd-highlight" key={v4()}>
                                <div className="p-2 w-100 bd-highlight" key={v4()}>
                                    <h2 className="text-secondary" key={v4()}>{this.props.title}</h2>
                                </div>
                                {this.props.actions.map(action => {
                                    if (action === "CREATE") {
                                        return (
                                            <div className="p-2 flex-shrink-1 bd-highlight" key={v4()}>
                                                <button className="btn btn-success" key={v4()} onClick={() => {
                                                    this.showCUD({
                                                        active: true,
                                                        code: "",
                                                        group: this.props.group,
                                                        value: ""
                                                    }, "CREATE");
                                                }}>
                                                    <i className="fas fa-plus-circle" key={v4()}></i>
                                                </button>
                                            </div>
                                        )
                                    }
                                    else
                                        return <></>
                                })

                                }

                            </div>
                            <div className="table-responsive mt-3" key={v4()}>
                                <table className="table caption-top" key={v4()}>
                                    {
                                        this.renderHeader()
                                    }
                                    <tbody key={v4()}>
                                        {
                                            this.state.rows.map(row => {
                                                return <tr key={v4()}>{this.renderRow(row)}</tr>;
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </div>

            </div>
        )
    }
}