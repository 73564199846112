import React, { Component } from "react";

export class LoadingComponent extends Component<{}, {}>{
    public render(): JSX.Element {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: "400px"}}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Cargando...</span>
                </div>
            </div>

        );
    }
}