import { NavLinkInfo } from "../pages";
import { GetEventCountResponse, getEvents } from "./events";

export const getBadges = async (links: NavLinkInfo[]): Promise<{
    [page: string]: {
        badgeClassName: string;
        textBadge: string;
    }
}> => {
    const badges: {
        [page: string]: {
            badgeClassName: string;
            textBadge: string;
        }
    } = {};
    const tR = [];
    for (const l of links) {
        if (l.name === "Eventos") {
            tR.push(getEvents({ bonificado: false, count: true }).then((r) => {
                const count = r as GetEventCountResponse;
                badges[l.name] = {
                    badgeClassName: l.badgeClassName,
                    textBadge: count.count ? String(count.count) : ""
                };
            }));
        } else if (l.name === "Bonos Emitidos") {
            tR.push(getEvents({ bonificado: true, count: true }).then((r) => {
                const count = r as GetEventCountResponse;
                badges[l.name] = {
                    badgeClassName: l.badgeClassName,
                    textBadge: count.count ? String(count.count) : ""
                };
            }));
        }
        // TODO call api to really get the badges
    }
    await Promise.allSettled(tR);
    return badges;
}