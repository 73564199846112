import React, { Component } from "react";
import { history } from "../../components";

export class HomeComponent extends Component<{ link?: string; }, {}>{
    public render(): JSX.Element {
        return (

            <div className="card mb-3" onClick={e => {
                e.preventDefault();
                if (this.props.link) {
                    history.push(this.props.link);
                }
            }}>
                <div className="d-flex justify-content-center">
                    <div style={{ width: "70%" }}>
                        <img src="./Banner-Labce-2021.jpeg" className="card-img" alt="..." />
                    </div>
                    <div className="card-img-overlay">
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100%" }}>
                            <h1 className="card-title" style={{ width: "66%", color: "#01305b" }}>Sistema de Reportes y Alertas</h1>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
