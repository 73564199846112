import React, { Component } from "react";
import "./pagination.css";

export const DEFAULT_LIMIT = 100;
export const DEFAULT_OFFSET = 0;

export interface PaginationComponentProps {
    onChange: (args: { offset: number; limit: number }) => void;
    offset: number;
    limit: number;
    count: number;
}

export class PaginationComponent extends Component<PaginationComponentProps, { editPage?: number | false; }> {
    constructor(props: PaginationComponentProps) {
        super(props);
        this.state = {};
    }
    componentDidUpdate() {
        const query = new URLSearchParams(window.location.search);
        let updated = false;
        const currentOffset = String(this.props.offset);
        const currentLimit = String(this.props.limit);
        if (!query.has("offset")) {
            query.set("offset", "0");
            updated = true;
        } else if (query.get("offset") !== currentOffset) {
            updated = true;
            query.set("offset", currentOffset);
        }
        if (!query.has("limit")) {
            query.set("limit", "100");
            updated = true;
        } else if (query.get("limit") !== currentLimit) {
            updated = true;
            query.set("limit", currentLimit);
        }
        if (updated) {
            const obj = {
                Title: document.title,
                Url: `${window.location.href.split("?")[0]}?${query.toString()}`
            };
            window.history.pushState(obj, obj.Title, obj.Url);
        }
    }
    public render() {
        return (
            <nav aria-label="Paginación" className="mt-3">
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <span
                            className={`page-link${this.props.offset - this.props.limit >= 0 ? "" : " page-link-disabled"}`}
                            onClick={e => {
                                e.preventDefault();
                                let offset = this.props.offset - this.props.limit;
                                offset = offset > 0 ? offset : 0;
                                if (offset !== this.props.offset) {
                                    this.props.onChange({
                                        offset,
                                        limit: this.props.limit
                                    });
                                }
                            }}
                        >Anterior</span>
                    </li>
                    {!this.state.editPage && <li
                        onClick={e => {
                            this.setState({
                                editPage: Math.ceil(this.props.offset / this.props.limit) + 1
                            });
                        }}
                        className="page-item">
                        {Math.ceil(this.props.offset / this.props.limit) + 1} / {Math.ceil(this.props.count / this.props.limit)}
                    </li>}
                    {this.state.editPage && <li
                        className="page-item">
                        <input
                            type="number"
                            value={this.state.editPage}
                            onChange={v => {
                                if (!isNaN(parseInt(v.target.value, 10)) && Math.ceil(parseInt(v.target.value, 10)) > 0) {
                                    this.setState({
                                        editPage: Math.ceil(parseInt(v.target.value, 10))
                                    });
                                } else {
                                    this.setState({
                                        editPage: false
                                    });
                                }
                            }} />
                        <button
                            className="btn btn-primary"
                            onClick={e => {
                                const newOffset = (this.state.editPage as number - 1) * this.props.limit;
                                this.setState({
                                    editPage: false
                                }, () => {
                                    if (newOffset >= 0 && newOffset <= Math.ceil(this.props.count / this.props.limit)) {
                                        this.props.onChange({
                                            offset: newOffset,
                                            limit: this.props.limit
                                        });
                                    } else {
                                        this.props.onChange({
                                            offset: (Math.ceil(this.props.count / this.props.limit) - 1) * this.props.limit,
                                            limit: this.props.limit
                                        });
                                    }
                                });
                            }}
                            type="submit">ir</button>
                    </li>}
                    <li className="page-item">
                        <span
                            className={`page-link${this.props.offset + this.props.limit < this.props.count ? "" : " page-link-disabled"}`}
                            onClick={e => {
                                e.preventDefault();
                                let offset = this.props.offset + this.props.limit;
                                offset = offset < this.props.count ? offset : this.props.offset;
                                if (offset !== this.props.offset) {
                                    this.props.onChange({
                                        offset,
                                        limit: this.props.limit
                                    });
                                }
                            }}
                        >Siguiente</span>
                    </li>
                </ul>
            </nav>
        );
    }
}