import { getEvents, GetEventsGroupResponse } from "../../endpoints/events";
import { EventGroup } from "../../components/Bonos/event-group";
import { DEFAULT_LIMIT, DEFAULT_OFFSET, PaginationComponent } from "../../components/Pagination";
import { v4 } from "uuid";
import "./style.css";
import { LoadingComponent } from "../../components/Loading";
import { setDefaultQuerys, QueryPageState } from "../../components/Pagination/query";
import { Component } from "react";

export * from "./detail";
export * from "./xml";

export interface EventosComponentState extends QueryPageState {
    events?: GetEventsGroupResponse;
    error?: string;
    loading?: boolean;
}

export class EventosComponent extends Component<{}, EventosComponentState> {

    constructor(props: {}) {
        super(props);
        this.state = setDefaultQuerys({
            query: [{
                name: "limit",
                default: DEFAULT_LIMIT
            }, {
                name: "offset",
                default: DEFAULT_OFFSET
            }]
        });
        this.updateList = this.updateList.bind(this);
    }

    public updateList() {
        this.setState({
            loading: true
        }, () => {
            getEvents({
                offset: this.state.query.offset as number,
                limit: this.state.query.limit as number,
                group: true,
                bonificado: false
            }).then((events) => {
                this.setState({
                    loading: false,
                    events: events as GetEventsGroupResponse
                });
            }).catch(e => {
                this.setState({
                    loading: false,
                    error: e.message
                });
            });
        });
    }

    componentDidMount() {
        this.updateList();
    }

    public render(): JSX.Element {
        let allCount = 0;
        if (this.state.events) {
            for (const a of this.state.events.count) {
                allCount += a.count;
            }
        }

        return (
            <div className="container">
                {this.state.error &&
                    <p>{this.state.error}</p>
                }
                {!this.state.error && this.state.loading &&
                    <LoadingComponent />
                }
                {!this.state.error && this.state.events && this.state.events.events && !this.state.loading &&
                    <>
                        <div className="p-2 w-100 bd-highlight">
                            <h2 className="text-secondary">Eventos</h2>
                        </div>
                        {this.state.events &&
                            <EventGroup
                                count={allCount}
                                message={`todas las solicitudes de bono no bonificadas`}
                                key={v4()}
                            />}
                        {this.state.events.events.map(e =>
                            <EventGroup
                                active={!e.ignore}
                                count={e.cantidad}
                                cod_prestacion={e.cod_prestacion}
                                message={`No se pudo bonificar prestación "${e.prestacion_desc}" con código ${e.cod_prestacion}`}
                                key={v4()}
                            />)
                        }
                        {this.state.events.count.length === 0 &&
                            <div className="p-2 w-100 bd-highlight">
                                <h2 className="text-secondary">no existen datos</h2>
                            </div>
                        }
                        {this.state.events.count.length > 0 && <PaginationComponent
                            count={this.state.events ? this.state.events.count.length : 0}
                            offset={this.state.query.offset as number}
                            limit={this.state.query.limit as number}
                            onChange={args => {
                                this.setState({
                                    query: {
                                        ...this.state.query,
                                        ...args
                                    }
                                }, () => {
                                    this.updateList();
                                });
                            }} />}
                    </>
                }
            </div>
        );
    }
}