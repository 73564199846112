import axios from "axios";

export const getUserInfo = async (): Promise<{
    clientPrincipal: {
        userDetails: string;
        userRoles: string[];
    } | null;
}> => {
    return (await axios.request({
        url: "/.auth/me",
        method: "GET"
    })).data;
}

