import { Component } from "react";
import { LoadingComponent } from "../../components/Loading";
import { ImportExcel } from "../../components/ImportExcel";
import { Cuadratura } from "../../components/Cuadratura";

export interface CuadraturaComponetState {
    loading: boolean;
    error?: string;
    tabs:{
        informes: boolean,
        newinforme: boolean
    }
}


export class CuadraturaComponent extends Component<{}, CuadraturaComponetState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: false,
            tabs:{
                informes: true,
                newinforme: false
            }
        }
    }

    public render(): JSX.Element {
        return (
            <div className="container mt-2">
                {this.state.error &&
                    <p>{this.state.error}</p>
                }
                {this.state.loading &&
                    <LoadingComponent />
                }
                {!this.state.loading && !this.state.error &&
                    <>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                    onClick={()=>this.setState({tabs: {...this.state.tabs, informes: true, newinforme: false}})}
                                    >
                                    Informes
                                </button>
                                <button
                                    className="nav-link"
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                    onClick={()=>this.setState({tabs: {...this.state.tabs, informes: false, newinforme: true}})}
                                    >
                                    Nuevo Informe
                                </button>

                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                {this.state.tabs.informes && 
                                     <Cuadratura />
                                }
                               
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                {this.state.tabs.newinforme &&
                                    <ImportExcel />
                                }
                                
                            </div>

                        </div>

                    </>

                }
            </div>

        );
    }
}