import axios from "axios";
import { stringify } from "querystring";
import { DataExcel } from "../../api/interfaces/excel";
import { DataHistory } from "../../api/interfaces/cuadre";

export interface ResultResponseConflict {
    status: string;
    message: string;
}

export interface ResultResponseCuadre {

    totales: {
        cobrado_imed_cargado: number;
        bonificado_imed_sap: number;
        seguros_complementarios_sap: number;
        excedentes: number;
    },
    numRegCargados: number
    numRegEncontradosSap: number;
    numRegActualizados: number;
    numRegSegComp: number;


}

export interface GetHistoryResponse {
    count: number;
    data: Array<DataHistory>;
}


export const instanceOfResultResponseCuadre = (object: any): object is ResultResponseCuadre => {
    return 'totales' in object && 
           'numRegCargados' in object && 
           'numRegEncontradosSap' in object && 
           'numRegActualizados' in object && 
           'numRegSegComp' in object;
}

export const instanceOfResultResponseConflict = (object: any): object is ResultResponseConflict => {
    return 'status' in object &&
           'message' in object;
}

/*
export const postCuadreImed = async (data: DataExcel[]): Promise<ResultResponseCuadre | ResultResponseConflict> => {

    try {
        const response = await axios.request({
            url: `/api/finance`,
            method: "POST",
            data
        });
        return response.data as ResultResponseCuadre;
    }
    catch (err: any) {
        return err.response.data as ResultResponseConflict;
    }

}
*/
export const postCuadreImed = async (data: DataExcel[]): Promise<{id: number} | ResultResponseConflict> =>{
    try{
        const response = await axios.request({
            url: `/api/finance`,
            method: "POST",
            data
        });
        return response.data as {id: number};
    }
    catch(err: any){
        return err.response.data as ResultResponseConflict
    }
}

export const getDetLoadExcel = async(args: {
    type: string,
    id: number
}): Promise<ResultResponseCuadre | {status: string}>=>{

    const response = await axios.request({
        url: `/api/finance?${stringify(args)}`,
        method: "GET"
    });
    if(instanceOfResultResponseCuadre(response.data)){
        return response.data as ResultResponseCuadre;
    }
    else{
        return response.data as {status: string};
    }
}

export const getHistoryCuadreImed = async (args: {
    limit: number,
    offset: number,
    type: string
}): Promise<GetHistoryResponse> => {

    return (await axios.request({
        url: `/api/finance?${stringify(args)}`,
        method: "GET"
    })).data as GetHistoryResponse;

}

export const getCuadreImedDetail = async (args: {
    id: number,
    type: string
}): Promise<any> => {
    return (await axios.request({
        url: `/api/finance?${stringify(args)}`
    })).data;
}

export const deleteCuadre = async (data: DataHistory): Promise<any> => {

    return (await axios.request({
        url: `/api/finance`,
        method: "DELETE",
        data
    })).data;
}