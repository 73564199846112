import React, { Component } from "react";
import * as XLSX from "xlsx";
import { DataExcel, DataSheet } from "../../../api/interfaces/excel";
import { postCuadreImed, ResultResponseCuadre, instanceOfResultResponseCuadre, instanceOfResultResponseConflict, getDetLoadExcel } from "../../endpoints/cuadre-imed";
import Swal from "sweetalert2";
import { numberToCLP, stringToDate } from "../util";

export interface ImportExcelProps {

}

export interface ImportExcelState {

    excel: Array<DataExcel>;
    form: {
        [key: string]: {
            className: string,
            value: any,
            valido: boolean
        }
    }
    loading: boolean;
    dataSap?: ResultResponseCuadre;
    dataExcel?: {
        totalReg: number;
        montoFact: number;
    }
}

export class ImportExcel extends Component<ImportExcelProps, ImportExcelState>{

    private intervalId: any;

    constructor(props: ImportExcelProps) {
        super(props);

        this.state = {
            excel: [],
            form: {
                "file": {
                    className: "",
                    value: {},
                    valido: false
                }
            },
            loading: false
        };
    }



    protected async handleOnChange(e: any): Promise<void> {
        const name = e.target.name;

        if (name === "file" && e.target.files.length > 0) {
            let reader = new FileReader();
            reader.readAsArrayBuffer(e.target.files[0]);
            reader.onload = async (e: any) => {
                let data = new Uint8Array(e.target.result);
                let workbook = XLSX.read(data, { type: "array", cellDates: true });
                const excel = Array<DataExcel>();

                workbook.SheetNames.forEach((sheetName) => {
                    const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    const dataSheet = Array<DataSheet>();
                    console.log(data);

                    for (const d of data as any[]) {
                        if (d["folio_bono"] && d['emision']) {
                            let delimiter = "";
                            let format = "";
                            if (typeof d['emision'] === "string") {
                                delimiter = d['emision'].indexOf("/") !== -1 ? "/" : "-";
                                format = `dd${delimiter}mm${delimiter}yyyy`;                                
                            }


                            dataSheet.push({
                                financiador: d['financiador'],
                                cod_lugar: d['codlugar'] && typeof d['codlugar'] === "number" ? d['codlugar'] : (typeof d['codlugar'] === "string" ? parseInt(d['codlugar']) : 0),
                                nombre_lugar: d['nombre_lugar'],
                                folio: typeof d['folio_bono'] === "number" ? d['folio_bono'] : (typeof d['folio_bono'] === "string" ? parseInt(d['folio_bono']) : 0),
                                estado_bono: d['estado'],
                                valor_prestacion: d['valor_total'] && typeof d['valor_total'] === "number" ? d['valor_total'] : (typeof d['valor_total'] === "string" ? parseInt(d['valor_total']) : 0),
                                valor_bonificacion: d['aporte_financiador'] && typeof d['aporte_financiador'] === "number" ? d['aporte_financiador'] : (typeof d['aporte_financiador'] === "string" ? parseInt(d['aporte_financiador']) : 0),
                                valor_seg_comp: d['aporte_seguro'] && typeof d['aporte_seguro'] === "number" ? d['aporte_seguro'] : (typeof d['aporte_seguro'] === "string" ? parseInt(d['aporte_seguro']) : 0),
                                valor_copago: d['copago_beneficiario'] && typeof d['copago_beneficiario'] === "number" ? d['copago_beneficiario'] : (typeof d['copago_beneficiario'] === "string" ? parseInt(d['copago_beneficiario']) : 0),
                                valor_fact_imed: d['aporte_financiador'] && typeof d['aporte_financiador'] === "number" ? d['aporte_financiador'] : (typeof d['aporte_financiador'] === "string" ? parseInt(d['aporte_financiador']) : 0),
                                fecha_emision: d['emision'] instanceof Date ? d['emision'] : stringToDate(d['emision'], format, delimiter),
                                rut_benef: d['rut_beneficiario'],
                                nombre_benef: d['nombre_beneficiario'],
                                rut_cajero: d['rut_cajero']
                            });
                        }
                        else
                            break;
                    }
                    excel.push({
                        data: dataSheet
                    });

                    //console.log(inspect(excel, {depth: 10}));

                    /* Archivo I-MED definido anteriormente
                    data.forEach((d: any) => {
                        if (d['Nro. BAS'] && d['Fecha Emisión']) {

                            dataSheet.push({
                                folio: d['Nro. BAS'],
                                valor_prestacion: d['Valor'] && typeof d['Valor'] === "number" ? d['Valor'] : 0,
                                valor_bonificacion: d['Bonificación'] && typeof d['Bonificación'] === "number" ? d['Bonificación'] : 0,
                                valor_copago: d['Copago'] && typeof d['Copago'] === "number" ? d['Copago'] : 0,
                                valor_fact_imed: d['A Cobrar'] && typeof d['A Cobrar'] === "number" ? d['A Cobrar'] : 0,
                                exige_documento: d['Exige Documento'],
                                fecha_emision: d['Fecha Emisión']

                            });
                        }
                        else {
                            return;
                        }


                    })

                    excel.push({
                        cod_financiador,
                        cod_lugar,
                        data: dataSheet
                    });
                     */

                });

                //const reg = [...excel[0].data];
                // console.log(inspect(excel, {depth: 10}));
                /*
                 for(const r of excel[0].data){
                    let i = excel[0].data.findIndex((e)=>e.folio===r.folio);
                    r.valor_bonificacion += excel[0].data[i].valor_bonificacion;
                    r.valor_copago += excel[0].data[i].valor_copago;
                    r.valor_fact_imed += excel[0].data[i].valor_fact_imed;
                    r.valor_prestacion += excel[0].data[i].valor_prestacion;
                    r.valor_seg_comp += excel[0].data[i].valor_seg_comp;
                    excel[0].data.splice(i,1);
                 }
                 */
                for (let i = 0; i < excel[0].data.length; i++) {
                    for (let j = i + 1; j < excel[0].data.length; j++) {
                        if (excel[0].data[i].folio === excel[0].data[j].folio) {
                            const data = excel[0].data[i];
                            data.valor_bonificacion += excel[0].data[j].valor_bonificacion;
                            data.valor_copago += excel[0].data[j].valor_copago;
                            data.valor_fact_imed += excel[0].data[j].valor_fact_imed;
                            data.valor_prestacion += excel[0].data[j].valor_prestacion;
                            data.valor_seg_comp += excel[0].data[j].valor_seg_comp;
                            excel[0].data.splice(j, 1);
                        }
                    }
                }

                this.setState({ excel, loading: true, dataSap: undefined }, async () => {

                    try {
                        const cobra_imed = this.state.excel[0].data.map(a => a.valor_fact_imed);
                        const montoFact = cobra_imed.reduce((a, b) => a + b, 0);
                        const totalReg = this.state.excel[0].data.length;
                        const data = await postCuadreImed(this.state.excel);
                        if(instanceOfResultResponseConflict(data)){
                            this.setState({ loading: false }, () => {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: `${data.message}`,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            });
                        }
                        else{
                           // data.id bla
                            this.intervalId = setInterval(async ()=>{
                                const detForLoad = await getDetLoadExcel({
                                    type: "load",
                                    id: data.id
                                });
    
                                if(instanceOfResultResponseCuadre(detForLoad)){
                                    this.setState({ loading: false, dataSap: detForLoad, dataExcel: { totalReg, montoFact } });
                                    clearInterval(this.intervalId);
                                }
                                else{
                                    if(detForLoad.status === "ERROR"){
                                        Swal.fire({
                                            position: 'center',
                                            icon: 'error',
                                            title: `Ha Ocurrido un error obteniendo los datos desde SAP`,
                                            showConfirmButton: false,
                                            timer: 3000
                                        });
                                        clearInterval(this.intervalId);
                                    }
                                }
                            }, 5000);
                            
                        }
                        /*
                        const dataSap = await postCuadreImed(this.state.excel);
                        if (instanceOfResultResponseCuadre(dataSap))
                            this.setState({ loading: false, dataSap, dataExcel: { totalReg, montoFact } });
                        else {
                            this.setState({ loading: false }, () => {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: `${dataSap.message}`,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            });
                        }
                        */


                    }
                    catch (e: any) {
                        this.setState({ loading: false }, () => {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: `Ha ocurrido un error, ${e.message}`,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        });
                    }



                });



            }
        }
    }

    validateForms(): boolean {

        for (const key in this.state.form) {
            if (key === "file") {
                const ee = this.state.form[key].value;
                if (ee && ee.target && ee.target.files && ee.target.files.length > 0) {
                    const form = { ...this.state.form };
                    form[key].className = "is-valid valid";
                    form[key].valido = true;
                    this.setState({ form });
                }
                else {
                    const form = { ...this.state.form };
                    form[key].className = "is-invalid invalid";
                    form[key].valido = false;
                    this.setState({ form });
                }
            }
            /*

            if (key === "select") {
                const e = this.state.form[key].value;

                if (e !== undefined && typeof e === "number") {
                    const form = { ...this.state.form };
                    form[key].className = "is-valid valid";
                    form[key].valido = true;
                    this.setState({ form });
                }
                else {
                    const form = { ...this.state.form };
                    form[key].className = "is-invalid invalid";
                    form[key].valido = false;
                    this.setState({ form });
                }
            }
            if (key === "select_lugar") {
                const e = this.state.form[key].value;

                if (e !== undefined && typeof e === "number") {
                    const form = { ...this.state.form };
                    form[key].className = "is-valid valid";
                    form[key].valido = true;
                    this.setState({ form });
                }
                else {
                    const form = { ...this.state.form };
                    form[key].className = "is-invalid invalid";
                    form[key].valido = false;
                    this.setState({ form });
                }
            }
            else if (key === "file") {
                const ee = this.state.form[key].value;
                if (ee && ee.target && ee.target.files && ee.target.files.length > 0) {
                    const form = { ...this.state.form };
                    form[key].className = "is-valid valid";
                    form[key].valido = true;
                    this.setState({ form });
                }
                else {
                    const form = { ...this.state.form };
                    form[key].className = "is-invalid invalid";
                    form[key].valido = false;
                    this.setState({ form });
                }
            }
            */
        }

        let isValid = true;

        for (const key in this.state.form) {
            if (this.state.form[key].valido === false)
                return this.state.form[key].valido
        }

        return isValid;

    }

    componentWillUnmount(): void{
        clearInterval(this.intervalId);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="row g-3 m-3">
                    <div className="col-sm-10">
                        <input
                            type="file"
                            className={`form-control ${this.state.form && this.state.form["file"] ? this.state.form["file"].className : ""}`}
                            id="validationCustom01"
                            name="file"
                            accept=".xlsx, .xls"
                            placeholder={"Se permiten solo archivos XLSX"}
                            onChange={async (e) => {
                                const form = { ...this.state.form };
                                form["file"].value = e;
                                this.setState({ form }, this.validateForms);
                            }}

                        />
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-success form-control" onClick={async (e) => {
                            if (this.validateForms()) {
                                const ee = this.state.form["file"].value;
                                await this.handleOnChange(ee);

                            }
                            else {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: `Todos los campos son requeridos`,
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                            }
                        }}
                            disabled={this.state.loading}
                        >

                            {this.state.loading &&
                                <div className="spinner-border text-light" role="status">
                                    <span className="visually-hidden"></span>
                                </div>
                            }
                            {!this.state.loading &&
                                <div>
                                    Procesar
                                </div>
                            }
                        </button>
                    </div>
                </div>
                {!this.state.loading && this.state.dataSap &&
                    <div className="d-flex justify-content-center">
                        <div className="card mt-3 p-2 shadow " style={{ width: "58rem" }}>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">&nbsp;</th>
                                        <th scope="col">I-MED Archivo</th>
                                        <th scope="col">I-MED Cargado</th>
                                        <th scope="col">SAP</th>
                                        <th scope="col">Seg. Comp. SAP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Monto Facturado</th>
                                        <td>{numberToCLP(this.state.dataExcel ? this.state.dataExcel.montoFact : 0)}</td>
                                        <td>{numberToCLP(this.state.dataSap ? this.state.dataSap.totales.cobrado_imed_cargado : 0)}</td>
                                        <td>{numberToCLP(this.state.dataSap ? this.state.dataSap.totales.bonificado_imed_sap : 0)}</td>
                                        <td>{numberToCLP(this.state.dataSap ? this.state.dataSap.totales.seguros_complementarios_sap : 0)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total De Registros</th>
                                        <td>{this.state.dataExcel ? this.state.dataExcel.totalReg : 0}</td>
                                        <td>{this.state.dataSap ? this.state.dataSap.numRegCargados : 0}</td>
                                        <td>{this.state.dataSap ? this.state.dataSap.numRegEncontradosSap : 0}</td>
                                        <td>{this.state.dataSap ? this.state.dataSap.numRegSegComp : 0}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        )
    }
}