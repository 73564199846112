import { Component } from "react";
import Swal from "sweetalert2";
import { EmailAlert, getContacts, addContacts, putContact, deleteContact } from "../../endpoints/conf-alertas";
import { LoadingComponent } from "../../components/Loading";
import { ParametersComponent } from "../../components/Parameters";

export interface ConfAlertasComponentState {
    contacts: Array<EmailAlert>;
    loading: boolean;
    error?: string;
}

export class ConfAlertasComponent extends Component<{}, ConfAlertasComponentState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            contacts: [],
            loading: false
        };

        this.addEmailAlert = this.addEmailAlert.bind(this);
        this.getContacts = this.getContacts.bind(this);
    }

    getContacts() {
        this.setState({ loading: true }, async () => {
            try {
                const contacts = await getContacts();
                this.setState({ contacts, loading: false });
            } catch (e: any) {
                this.setState({ error: e.message, loading: false });
            }
        });
    }

    componentDidMount() {
        this.getContacts();
    }

    addEmailAlert(c?: EmailAlert) {
        const contact: any = c ? c : {};
        const isNewEmailAlert = contact && contact.id;
        Swal.fire({
            title: `${isNewEmailAlert ? "Editar Usuario" : "Nuevo usuario"}`,
            html: `<input type="hidden" id="id" class="swal2-input" value="${isNewEmailAlert ? contact.id : ""}"/>
                   <input type="text" id="name" class="swal2-input" placeholder="Nombre" value="${contact && contact.nombre ? contact.nombre : ""}"/>
                   <input type="text" id="ape_paterno" class="swal2-input" placeholder="Apellido Paterno" value="${contact && contact.ape_paterno ? contact.ape_paterno : ""}"/>
                   <input type="text" id="ape_materno" class="swal2-input" placeholder="Apellido Materno" value="${contact && contact.ape_materno ? contact.ape_materno : ""}"/>
                   <input type="text" id="email" class="swal2-input" placeholder="Email" value="${contact && contact.email ? contact.email : ""}"/>
                    `,
            confirmButtonText: `${isNewEmailAlert ? "Actualizar" : "Crear"}`,
            confirmButtonColor: "#304c7d",
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                const id = (document.querySelector("#id") as any).value.toUpperCase();
                const nombre = (document.querySelector("#name") as any).value.toUpperCase();
                const apePaterno = (document.querySelector("#ape_paterno") as any).value.toUpperCase();
                const apeMaterno = (document.querySelector("#ape_materno") as any).value.toUpperCase();
                const email = (document.querySelector("#email") as any).value.toUpperCase();
                if (!email || !apeMaterno || !apePaterno || !nombre) {
                    Swal.fire("complete todos los datos");
                } else {
                    this.setState({ loading: true }, async () => {
                        try {
                            const args: EmailAlert = {
                                id,
                                nombre,
                                ape_paterno: apePaterno,
                                ape_materno: apeMaterno,
                                email,
                                estado: 1
                            };
                            if (isNewEmailAlert) {
                                await putContact(args);
                            } else {
                                await addContacts(args);
                            }
                            this.getContacts();
                        }
                        catch (e: any) {
                            this.setState({ error: e.message, loading: false });
                        }
                    });
                }
            }
        });
    }

    delContact(contact: EmailAlert) {
        deleteContact(contact).then(resp => {
            this.getContacts();
        }).catch(e => {
            this.setState({
                error: e.message
            });
        });
    }

    public render(): JSX.Element {
        return (
            <>
                <div className="container mt-2">
                    <div className="card shadow">
                        {this.state.error &&
                            <p>{this.state.error}</p>
                        }
                        {this.state.loading &&
                            <LoadingComponent />
                        }
                        {!this.state.loading && !this.state.error &&
                            <>
                                <div className="d-flex bd-highlight">
                                    <div className="p-2 w-100 bd-highlight">
                                        <h2 className="text-secondary">Configuración de emails para alertas</h2>
                                    </div>
                                    <div className="p-2 flex-shrink-1 bd-highlight">
                                        <button className="btn btn-success" onClick={() => this.addEmailAlert()}>
                                            <i className="fas fa-user-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive mt-3">
                                    <table className="table caption-top">

                                        <thead className="text-white" style={{ backgroundColor: "#304c7d" }}>
                                            <tr>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.contacts && this.state.contacts.map((contact) => {
                                                return (
                                                    <tr>
                                                        <td>{contact.nombre} {contact.ape_paterno} {contact.ape_materno}</td>
                                                        <td>{contact.email}</td>
                                                        <td style={{ cursor: "pointer" }}>
                                                            <i className="fas fa-edit ms-2 fa-lg text-warning" onClick={() => this.addEmailAlert(contact)}></i>
                                                            <i className="fas fa-trash-alt ms-3 fa-lg text-danger" onClick={() => this.delContact(contact)}></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>

                </div>
                <ParametersComponent
                    title="Número de días a visualizar en la aplicación"
                    group="day"
                    actions={["UPDATE"]}
                    attributes={["value"]}
                    translate={{
                        value: "Días a visualizar",

                    }}
                    refresh={true}
                />
                <ParametersComponent
                    title="Mantenedor de Centros"
                    group="centros"
                    actions={["UPDATE", "DELETE", "CREATE"]}
                    attributes={["code", "value"]}
                    translate={{
                        code: "Código",
                        value: "Centro"
                    }}
                    refresh={false}
                />
                <ParametersComponent
                    title="Mantenedor de Financiadores"
                    group="financiadores"
                    actions={["UPDATE", "DELETE", "CREATE"]}
                    attributes={["code", "value"]}
                    translate={{
                        code: "Código",
                        value: "Financiador"
                    }}
                    refresh={false}
                />
            </>
        );
    }
}