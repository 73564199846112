import { EventosComponent, EventosDetailComponent, XMLComponent } from "./Eventos";
import { BonosEmitidosComponent } from "./BonosEmitidos";
import { CuadraturaComponent, CuadraturaComponentDetail } from "./Cuadratura";
import { ConfAlertasComponent } from "./ConfAlertas";
import { v4 } from "uuid";

export interface NavLinkInfo {
    to: string;
    name: string;
    disableNavLink?: boolean;
    fontawesome: string;
    badgeClassName: string;
    render?: () => JSX.Element;
}

export const LinkMap: {
    [role: string]: {
        default: string;
        links: NavLinkInfo[]
    }
} = {
    admin: {
        default: "/eventos",
        links: [{
            to: "/eventos",
            name: "Eventos",
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <EventosComponent key={v4()}/>
        },
        {
            to: "/eventos/detail",
            name: "Eventos",
            disableNavLink: true,
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <EventosDetailComponent key={v4()}/>
        },
        {
            to: "/eventos/detail/xml",
            name: "Eventos",
            disableNavLink: true,
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <XMLComponent key={v4()}/>
        },
        {
            to: "/bonos-emitidos",
            name: "Bonos Emitidos",
            fontawesome: "fas fa-file-invoice",
            badgeClassName: "bg-primary",
            render: () => <BonosEmitidosComponent key={v4()}/>
        },
        {
            to: "/bonos-emitidos/xml",
            name: "Eventos",
            disableNavLink: true,
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <XMLComponent key={v4()}/>
        },
        {
            to: "/conf-alertas",
            name: "Conf. Sistema",
            fontawesome: "fas fa-envelope",
            badgeClassName: "",
            render: () => <ConfAlertasComponent key={v4()}/>
        },
        {
            to: "/cuadratura",
            name: "Cuadratura I-Med",
            fontawesome: "fas fa-chart-pie",
            badgeClassName: "",
            render: () => <CuadraturaComponent key={v4()}/>
        },
        {
            to: "/cuadratura/detail",
            name: "Cuadratura I-Med",
            disableNavLink: true,
            fontawesome: "fas fa-chart-pie",
            badgeClassName: "",
            render: () => <CuadraturaComponentDetail key={v4()}/>
        }
        ]
    },
    finnance: {
        default: "/cuadratura",
        links: [{
            to: "/cuadratura",
            name: "Cuadratura I-Med",
            fontawesome: "fas fa-chart-pie",
            badgeClassName: "",
            render: () => <CuadraturaComponent key={v4()}/>
        },
        {
            to: "/cuadratura/detail",
            name: "Cuadratura I-Med",
            disableNavLink: true,
            fontawesome: "fas fa-chart-pie",
            badgeClassName: "",
            render: () => <CuadraturaComponentDetail key={v4()}/>
        }
        ]
    },
    support: {
        default: "/eventos",
        links: [{
            to: "/eventos",
            name: "Eventos",
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <EventosComponent key={v4()}/>
        },
        {
            to: "/eventos/detail",
            name: "Eventos",
            disableNavLink: true,
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <EventosDetailComponent key={v4()}/>
        },
        {
            to: "/eventos/detail/xml",
            name: "Eventos",
            disableNavLink: true,
            fontawesome: "fa fa-bell",
            badgeClassName: "bg-danger",
            render: () => <XMLComponent key={v4()}/>
        },
        {
            to: "/conf-alertas",
            name: "Conf. Alertas",
            fontawesome: "fas fa-envelope",
            badgeClassName: "",
            render: () => <ConfAlertasComponent key={v4()}/>
        }
        ]
    },
    anonymous: {
        default: "/",
        links: []
    }
}