import React, { Component } from "react";
import { history } from "..";
import { LoadingComponent } from "../Loading";
import { ignoreEvent } from "../../endpoints";

export interface EventGroupProps {
    message: string;
    cod_prestacion?: string;
    active?: boolean;
    count: number;
}

export interface EventGroupState {
    active?: boolean;
    loading?: boolean;
}

export class EventGroup extends Component<EventGroupProps, EventGroupState>{
    constructor(props: EventGroupProps) {
        super(props);
        this.state = {
            active: this.props.active
        }
    }

    render(): JSX.Element {
        return (
            <div className="card card-event mt-3 p-3 shadow" style={{ borderLeftColor: "red", borderLeftWidth: ".25rem" }}>
                <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {this.props.count > 99 ? "99+" : this.props.count}
                    <span className="visually-hidden">unread messages</span>
                </span>
                <span
                    onClick={e => {
                        e.preventDefault();
                        if (this.props.cod_prestacion) {
                            history.push(`/eventos/detail?cod_prestacion=${this.props.cod_prestacion}`);
                        } else {
                            history.push(`/eventos/detail`);
                        }
                    }}
                    className="alert alert-danger mt-3 " role="alert">
                    <div className="">
                        {this.props.message}
                    </div>
                </span>
                {this.props.cod_prestacion && this.state.loading && <>
                    <LoadingComponent />
                </>}
                {this.props.cod_prestacion && !this.state.loading && <>
                    <div
                        className="d-flex justify-content-end">
                        <label className="switch">
                            <input type="checkbox" checked={this.state.active} onChange={() => {
                                this.setState({ active: !this.state.active, loading: true }, async () => {
                                    try {
                                        await ignoreEvent({ cod_prestacion: this.props.cod_prestacion as string, ignore: !this.state.active });
                                        this.setState({
                                            loading: false
                                        });
                                    } catch (e) {
                                        console.error(e);
                                        this.setState({
                                            active: !this.state.active,
                                            loading: false
                                        });
                                    }
                                });
                            }} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div
                        className="d-flex justify-content-end">
                        <span className={`${this.state.active ? "text-success" : "text-muted"}`}>{this.state.active ? "Activa" : "Desactivada"}</span>
                    </div>
                </>}
            </div>
        )
    }


}