import axios from "axios";
import { stringify } from "querystring";
import { bonos_vta } from "../../api/interfaces/bonos_vta";

export interface GetEventsGroupResponse {
    offset: number;
    limit: number;
    count: {
        cod_prestacion: string;
        bonificado: boolean;
        prestacion_desc: string;
        count: number;
    }[];
    events: {
        bonificado: boolean;
        cod_prestacion: string;
        ignore: boolean;
        prestacion_desc: string;
        cantidad: number;
    }[];
}

export interface GetEventsResponse {
    offset: number;
    limit: number;
    count: number;
    events: bonos_vta[];
}

export interface GetEventResponse {
    event: bonos_vta;
}

export interface GetEventCountResponse {
    count: number;
}

export const getEvents = async (args: {
    transaction_number?: number;
    cod_lugar?: number;
    cod_financiador?: number;
    episodio?: string;
    rut_benef?: string;
    fec_emi_start?: number,
    fec_emi_end?: number,
    folio?: number;
    id?: number;
    limit?: number;
    offset?: number;
    cod_prestacion?: string;
    group?: boolean;
    bonificado?: boolean;
    count?: boolean;
}): Promise<GetEventsGroupResponse | GetEventsResponse | GetEventResponse | GetEventCountResponse> => {
    return args.count ? (await axios.request({
        url: `/api/events?count=true&bonificado=${args.bonificado}`,
        method: "GET"
    })).data as GetEventCountResponse : args.id ? (await axios.request({
        url: `/api/events?id=${args.id}`,
        method: "GET"
    })).data as GetEventResponse : (await axios.request({
        url: `/api/events?${stringify(args)}`,
        method: "GET"
    })).data as GetEventsGroupResponse
}