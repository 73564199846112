import React, { Component } from "react";
import { GetEventResponse, getEvents } from "../../endpoints";
import { LoadingComponent } from "../../components/Loading";
import { history } from "../../components";

export interface XMLComponentState {
    loading: boolean;
    event?: GetEventResponse;
    error?: string;
};

export class XMLComponent extends Component<{}, XMLComponentState> {

    public constructor(props: {}) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const id = query.has("id") && !isNaN(parseInt(query.get("id") as string, 10)) ? parseInt(query.get("id") as string, 10) : undefined;
        if (!id) {
            history.push("/");
        } else {
            getEvents({
                id
            }).then(r => {
                this.setState({
                    event: r as GetEventResponse,
                    loading: false
                });
            }).catch(e => {
                this.setState({
                    error: e.message,
                    loading: false
                });
            });
        }
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                {this.state.error &&
                    <p>{this.state.error}</p>
                }
                {!this.state.error && this.state.loading &&
                    <LoadingComponent />
                }
                {!this.state.error && !this.state.loading && (!this.state.event || !this.state.event.event || !this.state.event.event.xml) &&
                    <>
                        {this.state.event && this.state.event.event &&
                            <div className="p-2 w-100 bd-highlight">
                                <h2 className="text-secondary">Transaccion {this.state.event.event.transaction_number}</h2>
                                <h3> Episodio {this.state.event.event.episodio}</h3>
                            </div>
                        }
                        <p className={"alert alert-danger"}>no hay datos</p>
                    </>
                }
                {!this.state.error && !this.state.loading && this.state.event && this.state.event.event && this.state.event.event.xml &&
                    <>
                        <div className="p-2 w-100 bd-highlight">
                            <h2 className="text-secondary">Transaccion {this.state.event.event.transaction_number}</h2>
                            <h3> Episodio {this.state.event.event.episodio}</h3>
                        </div>
                        <h3 className="text-secondary mt-3 mb-3">XML Recibido desde SAP</h3>
                        {this.state.event.event.xml.xml_salida_sap &&
                            <textarea
                                className="form-control rounded-3 mb-3"
                                rows={20}
                                value={Buffer.from(this.state.event.event.xml.xml_salida_sap).toString("utf-8")} />}
                        {!this.state.event.event.xml.xml_salida_sap && <p className={"alert alert-danger"}>no hay datos</p>}

                        <h3 className="text-secondary mb-3">XML Enviado hacia I-Med</h3>
                        {this.state.event.event.xml.xml_entrada_imed &&
                            <textarea
                                className="form-control rounded-3 mb-3"
                                rows={20}
                                value={Buffer.from(this.state.event.event.xml.xml_entrada_imed).toString("utf-8")} />}
                        {!this.state.event.event.xml.xml_entrada_imed && <p className={"alert alert-danger"}>no hay datos</p>}

                        <h3 className="text-secondary mb-3">XML Recibido desde I-Med</h3>
                        {this.state.event.event.xml.xml_salida_imed &&
                            <textarea
                                className="form-control rounded-3 mb-3"
                                rows={20}
                                value={Buffer.from(this.state.event.event.xml.xml_salida_imed).toString("utf-8")} />}
                        {!this.state.event.event.xml.xml_salida_imed && <p className={"alert alert-danger"}>no hay datos</p>}

                        <h3 className="text-secondary mb-3">XML Enviado hacia SAP</h3>
                        {this.state.event.event.xml.xml_entrada_sap &&
                            <textarea
                                className="form-control rounded-3 mb-3"
                                rows={20}
                                value={Buffer.from(this.state.event.event.xml.xml_entrada_sap).toString("utf-8")} />}
                        {!this.state.event.event.xml.xml_entrada_sap && <p className={"alert alert-danger"}>no hay datos</p>}
                    </>
                }
            </div>
        );
    }
}