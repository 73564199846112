import { Component } from "react";
import {getParameters, Parameter} from "../../endpoints/parameter";

export interface SearchComponentProps<T> {
    onChange: (value: T) => void;
    value?: T;
    label?: string;
    query: string;
    type: "number" | "string" | "date" | "select";
    placeholder?: string;
    small?: string;
    disabled?: boolean;
    group?: string;
    className?: string;
}

export interface SearchComponentState<T> {
    value?: T;
    disabled?: boolean;
    parameters?: Array<Parameter>;
}

export class SearchComponent<T = any> extends Component<SearchComponentProps<T>, SearchComponentState<T>> {
    constructor(props: SearchComponentProps<T>) {
        super(props);
        this.state = {
            value: props.value,
            disabled: props.disabled
        };
    }

    private notifyChange(value: T) {
        this.props.onChange(value);
    }

    async getData(): Promise<void>{
        if(this.props.group){
           const parameters =  await getParameters(this.props.group);
           this.setState({parameters});
        }          

    }

    componentDidUpdate() {
        if (this.state.disabled !== this.props.disabled && this.state.value === this.props.value) {
            this.setState({
                disabled: this.props.disabled
            });
        }
    }

    componentDidMount(){
        this.getData();
    }

    public render() {
        let value = "";
        if (this.props.type === "date" && this.state.value !== undefined) {
            const date = new Date(parseInt(this.state.value as any, 10));
            value = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1) >= 10 ? (date.getUTCMonth() + 1) : `0${(date.getUTCMonth() + 1)}`}-${date.getUTCDate() >= 10 ? date.getUTCDate() : `0${date.getUTCDate()}`}`;
        } else if (this.state.value !== undefined) {
            value = String(this.state.value);
        }
        return (
            <div className="form-group">
                {this.props.label && <label>{this.props.label}</label>}
                {this.props.type === "select" &&
                    <select className={`${this.props.className?this.props.className:"form-select"}`} aria-label="select" onChange={e=>{
                        
                        const n = e.target.value !== "" ? parseInt(e.target.value, 10) : undefined;
                        
                        this.setState({
                            value: n as unknown as T
                        }, () => {
                            this.notifyChange(n as unknown as T);
                        });
                        
                    }}>
                        <option selected value={""}>Seleccione {this.props.placeholder}</option>
                        {this.state.parameters?.map(parameter=>{
                            return(
                                <option value={parameter.code}>{`(${parameter.code}) - ${parameter.value}`}</option>
                            )
                        })
                            
                        }                        
                    </select>
                }
                {this.props.type !== "select" &&
                    <input
                        disabled={this.state.disabled}
                        value={value}
                        onChange={e => {
                            switch (this.props.type) {
                                case "date":
                                    const d = e.target.valueAsDate;
                                    this.setState({
                                        value: d ? d.getTime() as unknown as T : undefined
                                    }, () => {
                                        this.notifyChange(d ? d.getTime() as unknown as T : undefined as any);
                                    });
                                    break;
                                case "number":
                                    const n = e.target.value !== "" ? parseInt(e.target.value, 10) : undefined;
                                    if (n === undefined || !isNaN(n)) {
                                        this.setState({
                                            value: n as unknown as T
                                        }, () => {
                                            this.notifyChange(n as unknown as T);
                                        });
                                    } else {
                                        break;
                                    }
                                    break;
                                default:
                                    const t = e.target.value !== "" ? e.target.value : undefined;
                                    this.setState({
                                        value: t as unknown as T
                                    }, () => {
                                        this.notifyChange(t as unknown as T);
                                    });
                                    break;
                            }
                        }}
                        type={this.props.type}
                        className={`${this.props.className?this.props.className:"form-control"}`}
                        placeholder={this.props.placeholder} />
                }

                {this.props.small && <small className="form-text text-muted">{this.props.small}</small>}
                {!this.props.small && <br />}
            </div>

        )
    }
}