import axios from "axios"

export const ignoreEvent = async (args: {
    cod_prestacion: string;
    ignore: boolean
}): Promise<void> => {
    await axios.request({
        url: `/api/ignore?cod_prestacion=${args.cod_prestacion}`,
        method: "POST",
        data: {
            ignore: args.ignore
        }
    });
}