import axios from "axios";

export interface Parameter{
    code: string;
    value: string;
    group: string;
    active: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export const getParameters = async (group: string): Promise<Array<Parameter>> => {

    return (await axios.request({
        url: `/api/parameters?group=${group}`,
        method: "GET"
    })).data;
}

export const patchParameter = async (data: Parameter): Promise<Parameter> => {

    return (await axios.request({
        url: `/api/parameters`,
        method: "patch",
        data
    })).data;
}

export const postParameter = async(data: Parameter): Promise<Parameter>=>{

    return (await axios.request({
        url: `/api/parameters`,
        method: "POST",
        data 
    })).data;
}

export const deleteParameter = async(data: Parameter): Promise<Parameter>=>{

    return (await axios.request({
        url: `/api/parameters`,
        method: "DELETE",
        data 
    })).data;
}

