export const removeUndefinedAndEmpty = (args: { [attr: string]: string | number | undefined | boolean }): { [attr: string]: string | number | boolean } => {
    const ret: { [attr: string]: string | number | boolean } = {};
    const keys = Object.keys(args);
    for (const key of keys) {
        if (args[key] !== undefined && args[key] !== "") {
            ret[key] = args[key] as any;
        }
    }
    return ret;
}

export function numberToCLP(number: number): string {
    return "$" + new Intl.NumberFormat('de-DE').format(number);
}

export const stringToDate = (_date: string, _format: string, _delimiter: string): Date => {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf("mm");
    const dayIndex = formatItems.indexOf("dd");
    const yearIndex = formatItems.indexOf("yyyy");
    let month = parseInt(dateItems[monthIndex]);
    const year = parseInt(dateItems[yearIndex]);
    const day = parseInt(dateItems[dayIndex]);
    month -= 1;
    var formatedDate = new Date(year, month, day);
    return formatedDate;
}

