import { Component } from "react";
import { updateQuery } from "../Pagination/query";
import { SearchComponent } from "../Search/index";

export interface BonoSearchProps {
    onChange: (state: BonoSearchValues) => void;
    values?: BonoSearchValues;
    disabled?: boolean;
}

export interface BonoSearchValues {
    folio?: number;
    fec_emi_start?: number;
    fec_emi_end?: number;
    episodio?: string;
    cod_financiador?: number;
    cod_lugar?: number;
    rut_benef?: string;
    transaction_number?: number;
}

export interface BonoSearchState {
    values?: BonoSearchValues;
}

export class BonoSearch extends Component<BonoSearchProps, BonoSearchState> {
    constructor(props: BonoSearchProps) {
        super(props);
        this.state = {
            values: props.values
        };
        this.notifyChange = this.notifyChange.bind(this);
    }
    private notifyChange() {

        if (this.state.values) {
            const queryNames = Object.keys(this.state.values);

            for (const queryName of queryNames) {
                updateQuery(queryName, (this.state.values as any)[queryName]);
            }
        }

        this.props.onChange(this.state.values ? this.state.values : {});
    }
    public render() {

        return (
            <form
                className="card mt-2 shadow"
                onSubmit={e => {
                    e.preventDefault();
                    this.notifyChange();
                }}>
                <div className="row mt-2 p-2">
                    <div className="col-3">
                        <SearchComponent<number>
                            query="folio"
                            disabled={this.props.disabled}
                            value={this.state.values?.folio}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        folio: e
                                    }
                                })
                            }}
                            type="number"
                            placeholder="Folio"
                            small="Busque por folio" />
                    </div>
                    <div className="col-3">
                        <SearchComponent<string>

                            query="episodio"
                            disabled={this.props.disabled}
                            value={this.state.values?.episodio}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        episodio: e
                                    }
                                })
                            }}
                            type="string"
                            placeholder="Episodio"
                            small="Busque por episodio" />
                    </div>
                    <div className="col-3">
                        <SearchComponent<string>

                            query="rut_benef"
                            disabled={this.props.disabled}
                            value={this.state.values?.rut_benef}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        rut_benef: e
                                    }
                                })
                            }}
                            type="string"
                            placeholder="Rut Beneficiario"
                            small="Busque por rut beneficiario" />
                    </div>
                    <div className="col-3">
                        <SearchComponent<number>

                            query="cod_financiador"
                            disabled={this.props.disabled}
                            value={this.state.values?.cod_financiador}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        cod_financiador: e
                                    }
                                })
                            }}
                            type="select"
                            group="financiadores"
                            placeholder="Código Financiador"
                            small="Busque por codigo financiador" />
                    </div>

                </div>
                <div className="row mt-2 p-2">
                    <div className="col-3">
                        <SearchComponent<number>
                            query="cod_lugar"
                            disabled={this.props.disabled}
                            value={this.state.values?.cod_lugar}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        cod_lugar: e
                                    }
                                })
                            }}
                            type="select"
                            group="centros"
                            placeholder="Código de lugar"
                            small="Busque por código de lugar" />
                    </div>
                    <div className="col-3">
                        <SearchComponent<number>
                            query="transaction_number"
                            disabled={this.props.disabled}
                            value={this.state.values?.transaction_number}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        transaction_number: e
                                    }
                                })
                            }}
                            type="number"
                            placeholder="Numero de transacción"
                            small="Busque por numero de transacción" />
                    </div>
                    <div className="col-3">
                        <SearchComponent<number>
                            query="fec_emi_start"
                            disabled={this.props.disabled}
                            value={this.state.values?.fec_emi_start}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        fec_emi_start: e
                                    }
                                })
                            }}
                            type="date"
                            placeholder="Fecha desde"
                            small="Busque por fecha emisión desde"
                        />
                    </div>
                    <div className="col-3">
                        <SearchComponent<number>
                            query="fec_emi_end"
                            disabled={this.props.disabled}
                            value={this.state.values?.fec_emi_end}
                            onChange={e => {
                                this.setState({
                                    values: {
                                        ...this.state.values,
                                        fec_emi_end: e
                                    }
                                })
                            }}
                            type="date"
                            placeholder="Fecha Hasta"
                            small="Busque por fecha emisión hasta"
                        />
                    </div>
                </div>
                <div className="row mt-2 p-2">
                    <div className="d-flex justify-content-end">
                        <button
                            
                            className="btn btn-success"
                            onClick={()=>{
                                updateQuery("offset", "0");
                                
                            }}
                            >Buscar</button>
                    </div>

                </div>
            </form>
        )
    }
}