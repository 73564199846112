import { Component } from "react";
import { v4 } from "uuid";
import { LoadingComponent } from "../Loading";
import { DEFAULT_OFFSET, PaginationComponent } from "../../components/Pagination";
import { setDefaultQuerys, QueryPageState } from "../../components/Pagination/query";
import { getHistoryCuadreImed, GetHistoryResponse, deleteCuadre } from "../../endpoints/cuadre-imed";
import { DataHistory } from "../../../api/interfaces/cuadre";
import { numberToCLP } from "../util";
import { getParameters } from "../../endpoints/parameter";
import Swal from "sweetalert2";
import { history } from "..";


export interface CuadraturaProps {

}

export interface CuadraturaState extends QueryPageState {
    loading: boolean;
    error?: string;
    rows?: GetHistoryResponse;
    financiadores?: {
        [key: number]: string;
    }
    centros?: {
        [key: number]: string;
    }
}

export const traduceStatus: {
    [key: string] : string
} = {
    "STARTED": "Iniciado",
    "INPROGRESS": "En Progreso",
    "FINISHED": "Finalizado",
    "ERROR": "Error"
}

export class Cuadratura extends Component<CuadraturaProps, CuadraturaState>{


    constructor(props: CuadraturaProps) {
        super(props);
        this.state = {
            loading: true,
            ...setDefaultQuerys({
                query: [{
                    name: "limit",
                    default: 10
                }, {
                    name: "offset",
                    default: DEFAULT_OFFSET
                }, {
                    name: "type",
                    default: "history"
                }]
            })
        }
    }


    updateList(): void {

        this.setState({ loading: true }, async () => {
            const data = await getHistoryCuadreImed({
                offset: this.state.query.offset as number,
                limit: this.state.query.limit as number,
                type: this.state.query.type as string
            });
            this.setState({ loading: false, rows: data });
        });

        

    }


    async componentDidMount(): Promise<void> {

        const parameters = await getParameters("financiadores");
        const parameters1 = await getParameters("centros");
        const financiadores: {
            [key: string]: string
        } = {};
        const centros: {
            [key: string]: string
        } = {};


        for (let p of parameters) {
            financiadores[p.code] = p.value
        }

        for (let p of parameters1) {
            centros[p.code] = p.value
        }

        this.setState({ loading: false, financiadores, centros }, ()=>{
            this.updateList();
        });

        
    }

    renderHeader(): JSX.Element {

        return (
            <>
                <thead className="text-white" key={v4()} style={{ backgroundColor: "#304c7d" }}>
                    <tr>
                        <th scope="col" key={v4()} className="text-center">Id</th>
                        <th scope="col" key={v4()} className="text-center">Reg. Excel</th>
                        <th scope="col" key={v4()} className="text-center">Reg. SAP</th>
                        <th scope="col" key={v4()} className="text-center">Fecha Desde</th>
                        <th scope="col" key={v4()} className="text-center">Fecha Hasta</th>
                        <th scope="col" key={v4()} className="text-center">Folio Bono Desde</th>
                        <th scope="col" key={v4()} className="text-center">Folio Bono Hasta</th>
                        <th scope="col" key={v4()} className="text-center">Total IMed</th>
                        <th scope="col" key={v4()} className="text-center">Total SAP</th>
                        <th scope="col" key={v4()} className="text-center">Total S.Comp. IMed</th>
                        <th scope="col" key={v4()} className="text-center">Total S.Comp. SAP</th>
                        <th scope="col" key={v4()} className="text-center">Estado</th>
                        <th scope="col" key={v4()} className="text-center"></th>
                        <th scope="col" key={v4()} className="text-center">Acciones</th>
                    </tr>
                </thead>
            </>

        );

    };

    renderRow(row: DataHistory): JSX.Element {
        let backgroundColor = row.monto_reg_sap - row.monto_fact_imed === 0 ? "#FFFFFF" : "#F9D1C8";
        return (
            <tr key={v4()} style={{ backgroundColor, cursor: "pointer" }} onClick={(e) => {
                e.preventDefault();
                history.push(`/cuadratura/detail?id=${row.id}&type=detail`);
            }}>
                <td key={v4()} className='text-center'>
                    {row.id}
                </td>
                <td key={v4()} className='text-center'>
                    {row.num_reg_cargados}
                </td>
                <td key={v4()} className='text-center'>
                    {row.num_reg_actualizados}
                </td>
                <td key={v4()} className='text-center'>
                    {new Date(row.fecha_desde).toLocaleString("es-ES",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }
                    )}
                </td>
                <td key={v4()} className='text-center'>
                    {new Date(row.fecha_hasta).toLocaleString("es-ES",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }
                    )}
                </td>
                <td key={v4()} className='text-center'>
                    {row.folio_ini}
                </td>
                <td key={v4()} className='text-center'>
                    {row.folio_fin}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_fact_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_reg_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_seg_comp_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_seg_comp_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {traduceStatus[row.estado]}
                </td>
                <td key={v4()} className='text-center'>
                    {row.glosa?row.glosa:"OK"}
                </td>
                <td key={v4()} className='text-center'>
                    <i className="fas fa-trash-alt ms-3 fa-lg text-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar" key={v4()} style={{ cursor: "pointer" }} onClick={async (e) => {
                      
                        e.preventDefault();
                        e.stopPropagation();

                        try {
                            

                            Swal.fire({
                                position: 'center',
                                icon: 'info',
                                title: `¿Estas seguro de eliminar el registro?`,
                                html: `<h6>Id: ${row.id}</h6>
                                       <h6>Fecha desde: ${new Date(row.fecha_desde).toLocaleString("es-ES",
                                       {
                                           year: "numeric",
                                           month: "2-digit",
                                           day: "2-digit"
                                       }
                                   )}</h6>
                                       <h6>Fecha hasta: ${new Date(row.fecha_hasta).toLocaleString("es-ES",
                                       {
                                           year: "numeric",
                                           month: "2-digit",
                                           day: "2-digit"
                                       }
                                   )}</h6>
                                       `,
                                confirmButtonText: "Eliminar",
                                confirmButtonColor: "#304c7d",
                                showCancelButton: true,
                                cancelButtonText: "Cancelar"
                            }).then(async (result)=>{
                                if(result.isConfirmed){
                                    await deleteCuadre(row);                                   
                                    this.updateList();
                                }
                            })
                        }
                        catch (e: any) {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: `No se pudo eliminar el registro, ${e.message}`,
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }

                    }}></i>
                </td>
            </tr>
        );
    }

    renderBody(): JSX.Element {
        if (!this.state.loading && this.state.rows && this.state.rows.count > 0) {
            return (<>{this.state.rows.data.map(row => this.renderRow(row))}</>)
        }
        else {
            return (<></>)
        }
    }

    render(): JSX.Element {
        return (
            <>
                <div>
                    {this.state.error &&
                        <p>{this.state.error}</p>
                    }
                    {this.state.loading &&
                        <LoadingComponent />
                    }
                    {!this.state.loading && !this.state.error &&
                        <div className="table-responsive mt-3">
                            <table className="table caption-top table-hover">
                                {
                                    this.renderHeader()
                                }
                                <tbody>
                                    {
                                        this.renderBody()
                                    }
                                </tbody>
                            </table>
                        </div>

                    }
                    {!this.state.loading && this.state.rows && this.state.rows.count === 0 &&
                        <div className="p-2 w-100 bd-highlight">
                            <h2 className="text-secondary">no existen datos</h2>
                        </div>
                    }
                    {!this.state.loading && this.state.rows && this.state.rows.count > 0 &&
                        <PaginationComponent
                            count={this.state.rows ? this.state.rows.count : 0}
                            offset={this.state.query.offset as number}
                            limit={this.state.query.limit as number}
                            onChange={args => {
                                this.setState({
                                    query: {
                                        ...this.state.query,
                                        ...args
                                    }
                                }, () => {
                                    this.updateList();
                                });
                            }} />}
                </div>
            </>
        )
    }

}

