import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom';

import { LoginComponent } from "../pages/Login";
import { HomeComponent } from "../pages/Home";
import { Nav } from "./Nav";
import { LoadingComponent } from "./Loading";
import { NotFoundComponent } from "./NotFound";

import { LinkMap, NavLinkInfo } from '../pages';
import { getUserInfo } from "../endpoints";

import { createBrowserHistory } from 'history';
import { v4 } from "uuid";

export const history = createBrowserHistory();

interface AppState {
  loading: boolean;
  error?: string;
  defaultLink?: string;
  links: NavLinkInfo[];
  userName?: string;
  userRole?: string;
}

export class App extends Component<{}, AppState>{

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      links: []
    }
  }

  public componentDidMount() {
    this.setState({ loading: true }, async () => {
      try {
        const user = await getUserInfo();
        if (user.clientPrincipal !== null) {
          const roles = user.clientPrincipal.userRoles;
          const role = roles[0];
          const defaultLink = LinkMap[role as "admin" | "finnance" | "support"].default;
          const links = LinkMap[role as "admin" | "finnance" | "support"] ? (LinkMap[role as "admin" | "finnance" | "support"]).links : [];
          this.setState({
            loading: false,
            links,
            defaultLink,
            userName: user.clientPrincipal.userDetails,
            userRole: role
          });
        } else {
          this.setState({
            loading: false,
            userName: undefined
          });
        }
      } catch (e: any) {
        console.error(e.message);
        this.setState({ error: "ERROR" });
      }
    });
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.error &&
          <p>{this.state.error}</p>
        }
        {this.state.loading &&
          <LoadingComponent />
        }
        {!this.state.loading &&
          <>
            {this.state.userName && this.state.userRole &&
              <>
                <Nav
                  links={this.state.links}
                  userName={this.state.userName}
                  userRole={this.state.userRole}
                  logo="/logo.png"
                />
                <Switch>
                  <Route path="/nologin" exact>
                    <LoginComponent />
                  </Route>

                  <Route path="/" exact>
                    <HomeComponent link={this.state.defaultLink} />
                  </Route>

                  {this.state.links.map(link => {
                    return (
                      <Route key={v4()} path={`${link.to}`} exact>
                        {link.render ? link.render() : <h1>{link.name}</h1>}
                      </Route>
                    )
                  })}
                  <Route>
                    <NotFoundComponent />
                  </Route>
                </Switch>
              </>
            }
            {!this.state.userName &&
              <>
                <Switch>
                  <Route>
                    <LoginComponent />
                  </Route>
                </Switch>
              </>
            }
          </>
        }
      </>
    )
  }
}