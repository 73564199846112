import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavLinkInfo } from "../../pages";
import { getBadges } from "../../endpoints";
import "./nav.css";
import { v4 } from "uuid";

export interface NavProps {
    logo?: string;
    links: Array<NavLinkInfo>
    userName: string;
    userRole: string;
    navClassName?: string;
}

export interface NavState {
    badges: {
        [page: string]: {
            badgeClassName: string;
            textBadge: string;
        }
    };
}

export class Nav extends Component<NavProps, NavState>{
    private unMounted: boolean = false;
    private badgesTimeout: NodeJS.Timeout | null = null;
    constructor(props: NavProps) {
        super(props);
        this.state = {
            badges: {

            }
        }
        this.updateBadges = this.updateBadges.bind(this);
        this.sheduleBadgesUpdate = this.sheduleBadgesUpdate.bind(this);
    }

    componentDidMount() {
        this.updateBadges().finally(() => {
            //this.sheduleBadgesUpdate();
        });
    }

    private sheduleBadgesUpdate() {
        if (this.badgesTimeout)
            clearTimeout(this.badgesTimeout);
        if (!this.unMounted) {
            this.badgesTimeout = setTimeout(async () => {
                try {
                    this.updateBadges();
                } catch (e) {
                    console.error(e);
                }
                if (!this.unMounted) {
                    this.sheduleBadgesUpdate();
                }
            }, 5000);
        }
    }

    componentWillUnmount() {
        this.unMounted = true;
    }

    async updateBadges() {
        const badges = await getBadges(this.props.links);
        if (!this.unMounted) {
            this.setState({
                badges
            });
        }
    }

    public render(): JSX.Element {
        return (
            <nav className={this.props.navClassName ? this.props.navClassName : `navbar navbar-icon-top navbar-expand-lg navbar-light bg-light`}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={this.props.logo ? this.props.logo : "/logo.png"} alt="" className="d-inline-block align-text-top" style={{ width: "200px" }} />
                    </Link>
                    <>
                        <button className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse mt-3 justify-content-center" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                {this.props.links.filter(l => !!!l.disableNavLink).map(link => {
                                    return (
                                        <li
                                            key={v4()}
                                            className="nav-item me-3 py-2">
                                            <NavLink
                                                to={`${link.to}`}
                                                className="nav-link"
                                                activeClassName="active text-success"
                                                aria-current="page">
                                                <i className={`${link.fontawesome}`}>
                                                    {this.state.badges[link.name] &&
                                                        <span className={`badge ${this.state.badges[link.name].badgeClassName}`}>{this.state.badges[link.name].textBadge}</span>
                                                    }
                                                </i>
                                                {link.name}
                                            </NavLink>
                                        </li>
                                    )
                                })

                                }
                            </ul>
                        </div>
                        <div className="collapse navbar-collapse justify-content-end mt-3 p-3" id="navbarNavDropdown">

                            <div className="btn-group">
                                <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-user fa-2x"></i>
                                    <span className="ms-2">{this.props.userName}</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <div className="d-flex justify-content-center">
                                            <b>{this.props.userRole}</b>
                                        </div>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <form action="/logout" method="GET" className="d-flex justify-content-center"><input className="btn btn-outline-success my-2 my-sm-0 py-2" type="submit" value="Salir" /></form>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </>

                </div>
            </nav>
        )
    }

}