import React, { Component } from "react";

export class LoginComponent extends Component<{}, {}>{
    render(): JSX.Element {
        return (<>
            <div className="container mt-3">
                <div className="card bg-dark">
                    <img src="https://meds-reserva-online.s3.amazonaws.com/background_alt.png" alt="" style={{ height: "900px" }} />
                    <div className="card-img-overlay shadow d-flex justify-content-center">
                        <div className="card w-75 m-3">
                            <img src="https://meds.b-cdn.net/wp-content/uploads/logo-meds.png" className="card-img mt-3 ms-2" alt="" style={{ width: "200px" }} />
                            <h1 className="card-title d-flex justify-content-center mt-3">Reportes y Alertas I-Med</h1>
                            <hr></hr>
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "50%" }}>
                                <div>
                                    <form action="/login" method="GET">
                                        <button className="btn btn-outline-success my-2 my-sm-0 py-2 fs-1" type="submit">
                                            Ingresar
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
}