import { PaginationComponent, DEFAULT_OFFSET } from "../../components/Pagination";
import { LoadingComponent } from "../../components/Loading";
import Swal from "sweetalert2";
import { BonoVTA } from "../../components/Bonos/vta";
import { getEvents, GetEventsResponse } from "../../endpoints";
import { removeUndefinedAndEmpty } from "../../components/util";
import { BonoSearch } from "../../components/Bonos/search";
import { QueryPageState, setDefaultQuerys } from "../../components/Pagination/query";
import { Component } from "react";
import { v4 } from "uuid";

export interface BonosEmitidosComponentState extends QueryPageState {
    events?: GetEventsResponse;
    error?: string;
    loading?: boolean;
    cod_prestacion?: string;
}

export class BonosEmitidosComponent extends Component<{}, BonosEmitidosComponentState> {

    public constructor(props: {}) {
        super(props);
        this.state = setDefaultQuerys({
            query: [{
                name: "limit",
                default: 10
            }, {
                name: "offset",
                default: DEFAULT_OFFSET
            }, {
                name: "episodio"
            }, {
                name: "folio"
            }, {
                name: "fec_emi_start"
            }, {
                name: "fec_emi_end"
            }, {
                name: "cod_financiador"
            }, {
                name: "rut_benef"
            }, {
                name: "cod_lugar"
            }, {
                name: "transaction_number"
            }]
        });
        this.updateList = this.updateList.bind(this);
    }

    public updateList() {
        this.setState({
            loading: true
        }, () => {
            getEvents(removeUndefinedAndEmpty({
                offset: this.state.query.offset as number,
                limit: this.state.query.limit as number,
                cod_prestacion: this.state.cod_prestacion,
                group: false,
                bonificado: true,
                rut_benef: this.state.query.rut_benef as string,
                folio: this.state.query.folio as number,
                fec_emi_start: this.state.query.fec_emi_start as number,
                fec_emi_end: this.state.query.fec_emi_end as number,
                episodio: this.state.query.episodio as string,
                cod_financiador: this.state.query.cod_financiador as number,
                cod_lugar: this.state.query.cod_lugar as number,
                transaction_number: this.state.query.transaction_number as number,
            })).then((events) => {
                this.setState({
                    loading: false,
                    events: events as GetEventsResponse
                });
            }).catch(e => {
                this.setState({
                    loading: false,
                    error: e.message
                });
            });
        });
    }

    componentDidMount() {
        this.updateList();
    }

    private export() {
        const now = new Date();
        const monthNumber = now.getMonth() + 1;
        Swal.fire({
            title: `Seleccionar Mes`,
            html: `<input type="month" id="exportDate" class="swal2-input" placeholder="mes" value="${now.getFullYear()}-${monthNumber < 10 ? `0${monthNumber}` : monthNumber}"/>`,
            confirmButtonText: `Exportar`,
            confirmButtonColor: "#304c7d",
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                const monthString = (document.querySelector("#exportDate") as any).value;
                const monthSplit = monthString.split("-");
                const date = new Date(monthSplit[0], parseInt(monthSplit[1], 10) - 1, 10);
                //const date = (document.querySelector("#exportDate") as any).valueAsDate;
                window.open(`/api/csv/bonos.csv?bonificado=true&year=${date.getFullYear()}&month=${date.getMonth()}`);
            }
        });
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                {this.state.error &&
                    <p>{this.state.error}</p>
                }
                {!this.state.error &&
                    <>
                        <BonoSearch
                            values={this.state.query}
                            disabled={this.state.loading}
                            onChange={s => this.setState({ query: { ...this.state.query, ...s } }, () => {
                                this.updateList();
                            })} />
                        <div className="btn btn-success btn-sm mt-2" onClick={e => this.export()}>
                            exportar prestaciones con bonos emitidos (csv)
                        </div>
                        {!this.state.loading && this.state.events && this.state.events.events && this.state.events.events.map(e => {
                            return <BonoVTA key={v4()} bonoVta={e} link={"/bonos-emitidos/xml"} />
                        })}

                        {this.state.loading &&
                            <LoadingComponent key={v4()} />
                        }
                        {!this.state.loading && this.state.events && this.state.events.count === 0 &&
                            <div className="p-2 w-100 bd-highlight">
                                <h2 className="text-secondary">no existen datos</h2>
                            </div>
                        }
                        {!this.state.loading && this.state.events && this.state.events.count > 0 &&
                            <PaginationComponent
                                count={this.state.events ? this.state.events.count : 0}
                                offset={this.state.query.offset as number}
                                limit={this.state.query.limit as number}
                                onChange={args => {
                                    this.setState({
                                        query: {
                                            ...this.state.query,
                                            ...args
                                        }
                                    }, () => {
                                        this.updateList();
                                    });
                                }} />}
                    </>
                }
            </div>
        );
    }
}