import { Component } from "react";
import { v4 } from "uuid";
import { history } from "..";
import { bonos_vta } from "../../../api/interfaces/bonos_vta";
import { convenio } from "../../../api/interfaces/convenio";
import { prestacion } from "../../../api/interfaces/prestacion";
import {numberToCLP} from "../util";

export class BonoVTAPrestacion extends Component<{ prestacion: prestacion; }> {
    public render() {
        return (
            <tr>
                <td>{this.props.prestacion.cod_prestacion}</td>
                <td>{this.props.prestacion.prestacion_desc}</td>
                <td>{this.props.prestacion.cod_item}</td>
                <td>{this.props.prestacion.cantidad}</td>
                <td>{this.props.prestacion.recargo_hora}</td>
                <td>{numberToCLP(this.props.prestacion.monto_prestacion_sap)}</td>
                <td>{numberToCLP(this.props.prestacion.monto_prestacion)}</td>
                <td>{numberToCLP(this.props.prestacion.monto_bonificado)}</td>
                <td>{numberToCLP(this.props.prestacion.total_seg_comp)}</td>
                <td>{numberToCLP(this.props.prestacion.monto_copago)}</td>
                <td>{this.props.prestacion.bonificado === "S" ? "SI" : "NO"}</td>
                <td>{this.props.prestacion.bonificado === "S" ? `Prestación bonificada` : `Prestación NO bonificada`}</td>
            </tr>)
    }
}

export class BonoVTAConvenios extends Component<{ convenio: convenio; }> {

    public render() {
        return (
            <div className="card">
                <div className="table-responsive mt-3">
                    <table className="table caption-top">
                        <caption><h2><b>Convenio: {this.props.convenio.rut_convenio}</b></h2></caption>
                        <thead className="bg-success text-white">
                            <tr>
                                <th scope="col">Corr. Conv</th>
                                <th scope="col">RUT Tratante</th>
                                <th scope="col">RUT Sol</th>
                                <th scope="col">Nom. Sol</th>
                                <th scope="col">Especialidad</th>
                                <th scope="col">RUT Benef</th>
                                <th scope="col">Cajero</th>
                                <th scope="col">Ind. Urg</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.props.convenio.corr_conveio}</td>
                                <td>{this.props.convenio.rut_tratante}</td>
                                <td>{this.props.convenio.rut_solic}</td>
                                <td>{this.props.convenio.nom_solic}</td>
                                <td>{this.props.convenio.cod_especia}</td>
                                <td>{this.props.convenio.rut_benef}</td>
                                <td>{this.props.convenio.rut_cajero}</td>
                                <td>{this.props.convenio.ind_urg}</td>
                            </tr>
                            <tr>
                                <td colSpan={10}>
                                    <div className="table-responsive mt-3 border">

                                        {this.props.convenio.bonos && this.props.convenio.bonos.map(b => {
                                            return (
                                                <table key={v4()} className="table caption-top">
                                                    <caption key={v4()}><h3 key={v4()}><b key={v4()}>Bono</b></h3></caption>
                                                    <thead key={v4()} className="bg-info text-white">
                                                        <tr key={v4()}>
                                                            <th key={v4()} scope="col">Folio</th>
                                                            <th key={v4()} scope="col">Fec. Emi</th>
                                                            <th key={v4()} scope="col">Num. Presta</th>
                                                            <th key={v4()} scope="col">Excedente</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr key={v4()}>
                                                            <td key={v4()}>{b.folio}</td>
                                                            <td key={v4()}>{b.fec_emi ? new Date(b.fec_emi ? b.fec_emi : 0).toLocaleString() : ""}</td>
                                                            <td key={v4()}>{b.num_presta}</td>
                                                            <td key={v4()}>{numberToCLP(b.excedente)}</td>
                                                        </tr>
                                                        <tr key={v4()}>
                                                            <td colSpan={4} key={v4()}>
                                                                <div className="table-responsive mt-3" key={v4()}>
                                                                    <table className="table caption-top" key={v4()}>
                                                                        <caption key={v4()}><h3 key={v4()}><b key={v4()}>Prestaciones</b></h3></caption>
                                                                        <thead className="bg-warning text-white" key={v4()}>
                                                                            <tr key={v4()}>
                                                                                <th scope="col" key={v4()}>Cod. Prestación</th>
                                                                                <th scope="col" key={v4()}>Prestación</th>
                                                                                <th scope="col" key={v4()}>Cod. Item</th>
                                                                                <th scope="col" key={v4()}>Cantidad</th>
                                                                                <th scope="col" key={v4()}>Recargo Hora</th>
                                                                                <th scope="col" key={v4()}>Monto SAP</th>
                                                                                <th scope="col" key={v4()}>Monto Conv.</th>
                                                                                <th scope="col" key={v4()}>Monto Bon.</th>
                                                                                <th scope="col" key={v4()}>Seg. Comp.</th>
                                                                                <th scope="col" key={v4()}>Monto Copago.</th>
                                                                                <th scope="col" key={v4()}>Bonificado</th>
                                                                                <th scope="col" key={v4()}>Detalle</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody key={v4()}>
                                                                            {b.prestaciones && b.prestaciones.map(p => {
                                                                                return <BonoVTAPrestacion key={v4()} prestacion={p} />
                                                                            })}
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>);
                                        })}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }
}

export class BonoVTA extends Component<{ bonoVta: bonos_vta; link: string; }> {

    public render() {
        return <div className="card shadow rounded-3 border-primary mt-3 mb-3"
            onDoubleClick={e => {
                e.preventDefault();
                history.push(`${this.props.link}?id=${this.props.bonoVta.id}`);
            }}>
            <div className="card-header bg-primary text-white">
                <div className="row">
                    <div className="col-sm-4" >
                        <div className="row mb-3 ">
                            <label className="col-sm-4">Episodio:</label>
                            <label className="col-sm-8">{this.props.bonoVta.episodio}</label>
                        </div>
                    </div>
                    <div className="col-sm-4" >
                        <div className="row mb-3 ">
                            <label className="col-sm-4">Centro: </label>
                            <label className="col-sm-8">{this.props.bonoVta.cod_lugar}</label>
                        </div>
                    </div>
                    <div className="col-sm-4" >
                        <div className="row mb-3 ">
                            <label className="col-sm-6">Cod. Financiador: </label>
                            <label className="col-sm-6">{this.props.bonoVta.cod_financiador}</label>
                        </div>
                    </div>
                </div>
                <div className="fw-bold">
                    <div className="d-flex justify-content-center">
                        <label className="me-2 fw-bold">Transacción I-MED: </label>
                        <label className="fw-bold">{this.props.bonoVta.transaction_number}</label>
                    </div>
                </div>
            </div>
            <div className="container mb-3 mt-3">
                {this.props.bonoVta.glo_error !== "OK" &&
                    <div className="alert alert-danger" role="alert" key={v4()}>
                        {this.props.bonoVta.glo_error}
                    </div>
                }
                {this.props.bonoVta.convenios && this.props.bonoVta.convenios.map(c => <BonoVTAConvenios key={v4()} convenio={c} />)}
            </div>
        </div>
    }
}