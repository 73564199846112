import { Component } from "react";
import { v4 } from "uuid";
import { LoadingComponent } from "../../components/Loading";
import { QueryPageState, setDefaultQuerys } from "../../components/Pagination/query";
import { getCuadreImedDetail } from "../../endpoints/cuadre-imed";
import { numberToCLP } from "../../components/util";


export interface DataDetail {
    id: number,
    financiador: string;
    cod_lugar: number;
    nombre_lugar: string;
    fecha_desde: Date;
    fecha_hasta: Date;
    folio: number;
    fec_emi: Date;
    monto_prestacion_imed: number;
    monto_bonificado_imed: number;
    monto_seg_comp_imed: number;
    monto_copago_imed: number;
    monto_cobrado_imed: number;
    monto_prestacion_sap: number;
    monto_bonificado_sap: number;
    monto_otras_bon_sap: number;
    monto_excedentes_sap: number;
    monto_copago_sap: number;
    episodio: string;
    tipo_venta: string;
    rut_benef: string;
    nombre_benef: string;
    rut_cajero: string;
}


export interface CuadraturaComponentDetailState extends QueryPageState {

    error?: string;
    loading: boolean;
    data?: any;

}

export class CuadraturaComponentDetail extends Component<{}, CuadraturaComponentDetailState>{

    constructor(props: {}) {
        super(props);

        this.state = {
            ...setDefaultQuerys({
                query: [
                    {
                        name: "id"
                    },
                    {
                        name: "type"
                    }
                ]
            }),
            loading: false,

        }
    }

    componentDidMount(): void {
        this.setState({ loading: true }, async () => {
            const { id, type } = this.state.query;
            const response = await getCuadreImedDetail({
                id: id as number,
                type: type as string
            });



            this.setState({ loading: false, data: response });
        })
    }

    renderHeader(): JSX.Element {

        return (
            <thead className="text-white" key={v4()} style={{ backgroundColor: "#304c7d", position: "sticky", zIndex: 10, top: 0 }}>
                <tr>
                    <th scope="col" key={v4()} className="text-center">Financiador</th>
                    <th scope="col" key={v4()} className="text-center">Cod. Centro</th>
                    <th scope="col" key={v4()} className="text-center">Centro</th>
                    <th scope="col" key={v4()} className="text-center">Episodio</th>
                    <th scope="col" key={v4()} className="text-center">Fecha Emisión</th>
                    <th scope="col" key={v4()} className="text-center">Folio</th>
                    <th scope="col" key={v4()} className="text-center">RUT Benef.</th>
                    <th scope="col" key={v4()} className="text-center">Benef.</th>
                    <th scope="col" key={v4()} className="text-center">RUT Cajero</th>
                    <th scope="col" key={v4()} className="text-center">Tipo Venta</th>
                    <th scope="col" key={v4()} className="text-center">Val. Prest. Imed</th>
                    <th scope="col" key={v4()} className="text-center">Val. Bon. Imed</th>
                    <th scope="col" key={v4()} className="text-center">Val. Seg. Comp. Imed</th>
                    <th scope="col" key={v4()} className="text-center">Val. Copago. Imed</th>
                    <th scope="col" key={v4()} className="text-center">Val. Prest. SAP</th>
                    <th scope="col" key={v4()} className="text-center">Val. Bon. Sap</th>
                    <th scope="col" key={v4()} className="text-center">Val. Exc. Sap</th>
                    <th scope="col" key={v4()} className="text-center">Val. Seg. Comp. Sap</th>
                    <th scope="col" key={v4()} className="text-center">Val. Copago. Sap</th>
                    <th scope="col" key={v4()} className="text-center">Val. Cobra. Imed</th>
                    <th scope="col" key={v4()} className="text-center">Val. A Pagar</th>
                    <th scope="col" key={v4()} className="text-center">Dif.</th>
                </tr>
            </thead>
        )

    }

    renderRow(row: DataDetail): JSX.Element {
        let backgroundColor = row.monto_cobrado_imed - row.monto_bonificado_sap + row.monto_excedentes_sap === 0 ? "#FFFFFF" : "#F9D1C8";
        return (
            <tr key={v4()} style={{ backgroundColor, cursor: "pointer" }} >
                <td key={v4()} className='text-center'>
                    {row.financiador}
                </td>
                <td key={v4()} className='text-center'>
                    {row.cod_lugar}
                </td>
                <td key={v4()} className='text-center'>
                    {row.nombre_lugar}
                </td>
                <td key={v4()} className='text-center'>
                    {row.episodio}
                </td>
                <td key={v4()} className='text-center'>
                    {new Date(row.fec_emi).toLocaleString("es-ES",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }
                    )}
                </td>
                <td key={v4()} className='text-center'>
                    {row.folio}
                </td>
                <td key={v4()} className='text-center'>
                    {row.rut_benef}
                </td>
                <td key={v4()} className='text-center'>
                    {row.nombre_benef}
                </td>
                <td key={v4()} className='text-center'>
                    {row.rut_cajero}
                </td>
                <td key={v4()} className='text-center'>
                    {row.tipo_venta}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_prestacion_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_bonificado_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_seg_comp_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_copago_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_prestacion_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_bonificado_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_excedentes_sap !== null ? row.monto_excedentes_sap : 0)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_otras_bon_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_copago_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_cobrado_imed)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_bonificado_sap + row.monto_excedentes_sap)}
                </td>
                <td key={v4()} className='text-center'>
                    {numberToCLP(row.monto_cobrado_imed - (row.monto_bonificado_sap + row.monto_excedentes_sap))}
                </td>
            </tr>
        );

    }

    renderBody(): JSX.Element {
        if (!this.state.loading && this.state.data && this.state.data.count > 0) {
            return (<>{this.state.data.data.map((row: DataDetail) => this.renderRow(row))}</>)
        }
        else {
            return (<></>)
        }
    }

    sumDiferencias(): number {
        if (this.state.data === undefined)
            return 0;

        const ci: Array<number> = this.state.data.data.map((a: DataDetail) => {
            //row.monto_cobrado_imed - (row.monto_bonificado_sap + row.monto_excedentes_sap)
            return a.monto_cobrado_imed - (a.monto_bonificado_sap + a.monto_excedentes_sap);
        });
        const total = ci.reduce((a, b) => a + b, 0);
        return total;
    }

    renderFooter(): JSX.Element {


        return (
            <tr key={v4()} style={{ cursor: "pointer" }} >
                <td key={v4()} className='text-center' colSpan={10}><b>Totales:</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.state.data ? this.state.data.totales.total_prestacion_imed: 0)}</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.state.data ? this.state.data.totales.total_bonificado_imed: 0)}</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.state.data ? this.state.data.totales.total_seg_comp_imed: 0)}</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.state.data ? this.state.data.totales.total_copago_imed: 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? this.state.data.totales.total_prestacion_sap: 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? this.state.data.totales.total_bonificado_sap: 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? (this.state.data.totales.total_excedentes_sap !== null ? this.state.data.totales.total_excedentes_sap : 0) : 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? this.state.data.totales.total_seg_comp_sap: 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? this.state.data.totales.total_copago_sap: 0)}</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.state.data ? this.state.data.totales.total_cobrado_imed: 0)}</b></td>
                <td key={v4()} className='text-center' style={{color: "rgb(48, 76, 125)"}}><b>{numberToCLP(this.state.data ? this.state.data.totales.total_bonificado_sap + (this.state.data.totales.total_excedentes_sap !== null ? this.state.data.totales.total_excedentes_sap : 0) : 0)}</b></td>
                <td key={v4()} className='text-center'><b>{numberToCLP(this.sumDiferencias())}</b></td>
            </tr>
        )
    }

    render(): JSX.Element {

        return (
            <div>
                {this.state.error &&
                    <p>{this.state.error}</p>
                }
                {this.state.loading &&
                    <LoadingComponent />
                }
                {!this.state.loading && !this.state.error &&
                    <>
                        <div className="container-fluid p-3 mb-2 bg-light text-dark">
                            <div className="row">
                                <div className="col-6">
                                    <div className="d-flex justify-content-start">
                                        <div className="d-flex flex-column">
                                            <h4>Estudio N°: {this.state.query.id}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-end me-3">
                                        <button className="btn btn-success" onClick={(e) => {
                                            const { id } = this.state.query;
                                            window.open(`/api/finance?type=export&id=${id}`);
                                        }}>
                                            <i className="fas fa-file-excel fa-2x p-2"></i>
                                            <i className="fas fa-download"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="table-responsive mt-3 p-2" style={{ height: "600px", overflowY: "scroll" }}>
                            <table className="table caption-top table-hover">
                                {
                                    this.renderHeader()
                                }
                                <tbody>
                                    {
                                        this.renderBody()
                                    }
                                    {
                                        this.renderFooter()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                {!this.state.loading && this.state.data && this.state.data.count === 0 &&
                    <div className="p-2 w-100 bd-highlight">
                        <h2 className="text-secondary">no existen datos</h2>
                    </div>
                }

            </div>
        )
    }
}