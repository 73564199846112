import axios from "axios";

export interface EmailAlert {
    id?: number;  
    nombre: string;
    ape_paterno: string;
    ape_materno?: string;
    email: string;
    estado: 0 | 1;
    updatedAt?: Date;
    createdAt?: Date;
}

export const getContacts = async (): Promise<Array<EmailAlert>>=>{

    return (await axios.request({
        url: `/api/conf-email`,
        method: "GET"
    })).data.contacts;

}

export const addContacts = async(contact: EmailAlert): Promise<EmailAlert>=>{
    return (await axios.request({
        url: `/api/conf-email`,
        method: "POST",
        data: contact
    })).data.newContact;
}

export const putContact = async(contact: EmailAlert): Promise<any>=>{
    return (await axios.request({
        url: `/api/conf-email`,
        method: "PUT",
        data: contact
    })).data.updateContact;
}

export const deleteContact = async(contact: EmailAlert): Promise<any>=>{
    return (await axios.request({
        url: `/api/conf-email`,
        method: "DELETE",
        data: contact
    })).data.updateContact;
}